<template>
  <CRow v-if="userData">
    <CCol lg="12">
      <h1>ข้อมูลผู้ใช้งาน</h1>
    </CCol>
    <CCol lg="4">
      <img src="../assets/Avatar.png" class="c-avatar-img" style="height: 60%; width: auto" />
    </CCol>
    <CCol lg="8">
      <h3>ชื่อ: {{ userData.user_fname }} {{ userData.user_lname }}</h3>
      <h5>UID: {{ userData.user_id }}</h5>
      <h5>Username: {{ userData.username }}</h5>
      <h5>โรงเรียน: {{ userData.school_name }}</h5>
    </CCol>
    <CCol lg="12" style="margin-top:20px">
      <h1>ตั้งค่ารหัสผ่านใหม่</h1>
    </CCol>
    <CCol lg="2" style="margin-top: 20px"><strong>รหัสผ่านใหม่: </strong></CCol>
    <CCol lg="4" style="margin-top: 20px">
      <input type="text" name="newPassword" class="form-control" v-model="newPw" @input="checkValidPw" required />
    </CCol>
    <CCol lg="2" style="margin-top: 20px"><strong>ยืนยันรหัสผ่าน: </strong></CCol>
    <CCol lg="4" style="margin-top: 20px">
      <input type="text" name="confirmPw" class="form-control " v-model="confirmPw" @input="checkValidPw" required />
      <div class="alert alert-danger" v-if="isWrong" style="margin-top:20px">
        ต้องกรอกให้ตรงกันทั้ง 2 ช่อง
      </div>
    </CCol>

    <CCol lg="12" class="text-right" style="margin-top:20px">
      <form id="formUpdateProfile" v-on:submit.prevent="updateProfile" method="POST">
        <button type="submit" class="btn btn-warning" :disabled="isWrong">บันทึก</button>
      </form>
    </CCol>

  </CRow>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx
import SltDistrict from "./components/SltDistrict";
import SltStudent from "./components/SltStudent.vue";
import usersData from "./users/UsersData";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    Datepicker,
    SltDistrict,
    SltStudent,
  },
  data() {
    return {
      darkModal: false,
      exportingData: null,
      teacherInfo: null,
      nowMonth: null,
      nowYear: null,
      userData: this.$cookies.get("user"),
      selectedDate: null,
      thMonth: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      relationshipInfo: null,
      centerPosition: { lat: 0.0, lng: 0.0 },
      markerPointer: { position: { lat: null, lng: null } },
      markers: [{ position: { lat: 10.2, lng: 10.2 } }],
      pinnedPosition: { lat: null, lng: null },
      location: null,
      gettingLocation: false,
      errorStr: null,
      newPw:null,
      confirmPw:null,
      isWrong:true
    };
  },
  created() {

  },
  mounted() {

  },
  
  methods: {
    checkValidPw(){
      if(this.newPw === this.confirmPw){
        this.isWrong = false;
      }
      else{
        this.isWrong = true;
      }
    },
    updateProfile(event) {
      //SEND DATA

      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "updateStudent");
      formData.append(
        "closeFriends",
        JSON.stringify(this.$store.state.selectedStudent)
      );

      if (
        !this.$store.state.selectedDistrict ||
        this.$store.state.selectedDistrict == 99
      ) {
        alert("กรุณาระบุจังหวัดเพื่อเลือกตำบล");
        return false;
      }
      formData.append("district", this.$store.state.selectedDistrict.code);
      formData.append("userId", this.userData.stud_id);
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.updateState == "updated") {
            alert("บันทึกสำเร็จ");
            alert("กำลัง logout เพิ่อ update ข้อมูล");
            this.$cookies.remove("user");
            location.reload();
          } else {
            alert(response.data.error);
          }
        });
    },

   
    getSelectedMonth(dataDate) {
      let d = new Date();
      if (dataDate) {
        d = new Date(dataDate);
      }
      this.nowYear = d.getFullYear() + 543;
      //alert(d.toISOString());
      //alert(d.getMonth());
      this.nowMonth = this.thMonth[d.getMonth()];
    },
    showPosition(position) {
      //console.log(position);
      //alert(position.coords.accuracy);
      //console.log(position.coords.latitude);
      //console.log(position.coords.longitude);
      this.centerPosition.lat = position.coords.latitude;
      this.centerPosition.lng = position.coords.longitude;
      //this.markers[0].position.lat = this.centerPosition.lat;
      //this.markers[0].position.lng = this.centerPosition.lng;
    },
    updatePosition(event) {
      this.pinnedPosition.lat = event.latLng.lat();
      this.pinnedPosition.lng = event.latLng.lng();
    },
  },
};
</script>

<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
